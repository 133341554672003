import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Papa from 'papaparse';
import ColoredButton from '../../common/ColoredButton';
import Select, { createFilter } from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import OutlineButton from '../../common/OutlineButton';
import {
  CSV_HEADER,
  priorityFormSelect,
  RATE_TYPES_SELECT,
  JOB_PRIORITY_LOW,
  RATE_TYPE_PER_CODE,
} from '../../../constants/jobConstants';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import { createCustomJob } from '../../../services/jobServices';
import { parseError } from '../../../helpers/errorHelper';
import { formSelectStyle } from '../../../helpers/selectHelper';
import {
  INCLUDE_EXCLUDED_GROUPS_LABEL,
  MAX_TEXT_LENGTH,
  QUICK_JOB_CREATION_LABEL,
} from '../../../constants/constants';
import { enterPressed } from '../../../utils/helper';
import { WindowedMenuList } from 'react-windowed-select';
import { isMapper, isSuperAdmin } from '../../../helpers/userHelper';
import ExcludedGroupsTooltip from './ExcludedGroupsTooltip';
import NumberInputWithoutScroll from '../../common/NumberInputWithoutScroll';
import { VSO_TEAM_ROLE } from '../../../constants/roles';

export default function CustomJobForm({ onCancel, onSave, onJobStatusFailed }) {
  const [formattedCSV, setFormattedCSV] = useState(null);
  const [serverError, setServerError] = useState(null);
  const [areButtonsDisabled, disableButtons] = useState(false);
  const { activeUsersOnly } = useSelector((state) => state.jobsTable);
  const { userInfo } = useSelector((state) => state.user);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    watch,
    setValue,
    trigger,
    setError,
  } = useForm();
  const assignedUser = watch('assign_to', false);
  const includeExcludedCodes = watch('include_excluded_codes');
  let assignToUsers = activeUsersOnly?.filter((u) => u.role !== VSO_TEAM_ROLE);

  useEffect(() => {
    if (formattedCSV) {
      trigger('csv_upload');
    }
  }, [formattedCSV]);

  useEffect(() => {
    if (!assignedUser || !isMapper(assignedUser)) {
      setValue('hidden_from_mapper', false);
    }
  }, [assignedUser]);

  const quickJobCreation = watch('quick_job_creation');

  const submitForm = (data) => {
    disableButtons(true);

    data.file = formattedCSV.file;
    if (quickJobCreation) {
      data.include_excluded_codes = true;
      data.claimed_by = userInfo.id;
      data.priority = JOB_PRIORITY_LOW;
      data.project_tracking = 'VetSuccess';
      data.rate = 0;
      data.rate_type = RATE_TYPE_PER_CODE;
      data.vaccine_protocol = { dhpp: '', fvrcp: '', rabies: '' };
      data.available_at = moment(new Date()).format('YYYY-MM-DD');
      data.admin_notes = '';
      data.hidden_from_mapper = false;
    } else {
      data.priority = data.priority.value;
      data.rate_type = data.rate_type.value;
      data.include_excluded_codes = data.include_excluded_codes === 'Yes';
      if (data.assign_to) data.claimed_by = data.assign_to.id;
      data.available_at = moment(data.available_at).format('YYYY-MM-DD');
    }

    delete data.practice;
    delete data.assign_to;
    delete data.csv;
    delete data.csv_upload;
    delete data.quick_job_creation;

    createCustomJob(data)
      .then(async () => {
        await onSave();
        disableButtons(false);
        onCancel();
      })
      .catch((err) => {
        disableButtons(false);
        if (err.response && err.response.status === 400) {
          onCancel();
          onJobStatusFailed({ error: err });
        } else {
          setServerError(parseError(err).description);
        }
      });
  };

  const validateFileSize = () => {
    if (!formattedCSV?.file) return 'CSV file is required';
    return (
      formattedCSV.file.size < 1000000 || 'Maximum supported file size is 1MB'
    );
  };

  const validateFileFormat = () => {
    return (
      ['text/csv'].includes(formattedCSV.file.type) ||
      'Only CSV files are supported'
    );
  };

  const validateFileContent = () => {
    const header = formattedCSV.data[0][0];
    return (
      CSV_HEADER.includes(header.toLowerCase()) ||
      `Header has to be ${CSV_HEADER[0]}`
    );
  };

  const validateNumberOfCodes = () => {
    const numberOfCodes = formattedCSV.data.length - 1;
    if (numberOfCodes < 1) {
      return 'Custom job has no codes';
    } else if (numberOfCodes > 20000) {
      // eslint-disable-next-line quotes
      return "Custom job can't have more than 20.000 codes";
    }
    return true;
  };

  const formatCsv = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const parsedData = Papa.parse(e.target.result, {
          skipEmptyLines: true,
        }).data;

        // Filter out everything except codes
        let headerIndex = parsedData[0].findIndex((d) =>
          CSV_HEADER.includes(d.toLowerCase())
        );
        if (headerIndex === -1) {
          headerIndex = 0;
        }
        const formattedData = parsedData.map((p) => [p[headerIndex]]);

        const formattedFile = new File(
          [Papa.unparse(formattedData)],
          file.name,
          {
            type: file.type,
          }
        );

        setFormattedCSV({ data: formattedData, file: formattedFile });
      } catch (error) {
        setError('csv_upload', {
          type: 'upload',
          message: 'Error processing CSV file',
        });
      }
    };

    try {
      reader.readAsText(file);
    } catch (error) {
      setError('csv_upload', {
        type: 'upload',
        message: 'Error reading CSV file',
      });
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(submitForm)}
        onKeyDown={(e) => {
          enterPressed(e) && e.preventDefault();
        }}
      >
        <div className="relative mb-5 mt-8">
          <Controller
            control={control}
            name="csv"
            render={({ field: { value, ...field } }) => {
              return (
                <input
                  {...field}
                  data-testid="csv_upload_input"
                  onInput={(e) => {
                    formatCsv(e.target.files[0]);
                    clearErrors('csv_upload');
                  }}
                  {...register('csv_upload', {
                    validate: {
                      size: validateFileSize,
                      format: validateFileFormat,
                      header: validateFileContent,
                      codes: validateNumberOfCodes,
                    },
                  })}
                  className="text-gray w-full"
                  type="file"
                  id="csv_upload"
                  title={value?.fileName}
                />
              );
            }}
          />
          {errors.csv_upload?.type === 'size' && (
            <p className="formError">{errors.csv_upload.message}</p>
          )}
          {errors.csv_upload?.type === 'format' && (
            <p className="formError">{errors.csv_upload.message}</p>
          )}
          {errors.csv_upload?.type === 'header' && (
            <p className="formError">{errors.csv_upload.message}</p>
          )}
          {errors.csv_upload?.type === 'codes' && (
            <p className="formError">{errors.csv_upload.message}</p>
          )}
          {errors.csv_upload?.type === 'upload' && (
            <p className="formError">{errors.csv_upload.message}</p>
          )}
        </div>
        {isSuperAdmin(userInfo) && (
          <div className="relative mb-5 px-1 items-center">
            <input
              id="quick_job_creation"
              type="checkbox"
              className="border border-gray rounded-sm bg-white focus:ring-transparent"
              {...register('quick_job_creation')}
            />
            <label
              htmlFor="quick_job_creation"
              className="ml-1 text-xs text-gray-400"
            >
              <span>{QUICK_JOB_CREATION_LABEL}</span>
            </label>
          </div>
        )}
        {!quickJobCreation && (
          <>
            <div className="relative mb-8">
              <div className="inline-flex items-end">
                <label
                  htmlFor="include_excluded_codes"
                  className="block mb-2 text-xs text-gray-400"
                >
                  {INCLUDE_EXCLUDED_GROUPS_LABEL}
                </label>
                <ExcludedGroupsTooltip />
              </div>
              <div className="flex">
                <ColoredButton
                  submit={false}
                  disabled={areButtonsDisabled}
                  border={
                    includeExcludedCodes !== 'Yes'
                      ? 'border border-light-border-gray hover:border-sky-blue'
                      : 'border border-sky-blue'
                  }
                  fillColorClass={
                    includeExcludedCodes !== 'Yes'
                      ? 'bg-white'
                      : 'bg-light-blue'
                  }
                  hoverColorClass="hover:bg-light-blue"
                  widthClass="w-[126px]"
                  heightClass="h-[38px]"
                  text="Yes"
                  textColor={
                    includeExcludedCodes !== 'Yes'
                      ? 'text-light-gray-text hover:text-sky-blue'
                      : 'text-sky-blue'
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setValue('include_excluded_codes', 'Yes');
                    trigger('include_excluded_codes');
                  }}
                  additionalClasses="mr-4"
                />
                <ColoredButton
                  submit={false}
                  disabled={areButtonsDisabled}
                  border={
                    includeExcludedCodes !== 'No'
                      ? 'border border-light-border-gray hover:border-sky-blue'
                      : 'border border-sky-blue'
                  }
                  fillColorClass={
                    includeExcludedCodes !== 'No' ? 'bg-white' : 'bg-light-blue'
                  }
                  hoverColorClass="hover:bg-light-blue"
                  widthClass="w-[126px]"
                  heightClass="h-[38px]"
                  text="No"
                  textColor={
                    includeExcludedCodes !== 'No'
                      ? 'text-light-gray-text hover:text-sky-blue'
                      : 'text-sky-blue'
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setValue('include_excluded_codes', 'No');
                    trigger('include_excluded_codes');
                  }}
                />
              </div>
              <input
                type="hidden"
                aria-label={INCLUDE_EXCLUDED_GROUPS_LABEL}
                {...register('include_excluded_codes', {
                  required: 'This field is required.',
                })}
              />
              {errors.include_excluded_codes?.type === 'required' && (
                <p className="formError">
                  {errors.include_excluded_codes.message}
                </p>
              )}
            </div>

            <div className="relative mb-9">
              <Controller
                id="assign_to"
                name="assign_to"
                control={control}
                render={({ field }) => (
                  <Select
                    components={{ MenuList: WindowedMenuList }}
                    filterOption={createFilter({ ignoreAccents: false })}
                    id="assign_to"
                    inputId="assign_to-input"
                    {...field}
                    className="formInput select"
                    isDisabled={areButtonsDisabled}
                    isClearable={true}
                    options={assignToUsers}
                  />
                )}
              />
              <label htmlFor="assign_to-input" className="formLabel">
                Assign to
              </label>
              <p className="absolute text-gray-400 mt-1 text-xs min-h-[20px]">
                Once assigned user can not be changed.
              </p>
            </div>
            {assignedUser && isMapper(assignedUser) && (
              <div className="relative mb-8">
                <input
                  id="hidden_from_mapper"
                  type="checkbox"
                  className="border border-gray rounded-sm bg-white focus:ring-transparent"
                  {...register('hidden_from_mapper')}
                />
                <label
                  htmlFor="hidden_from_mapper"
                  className="ml-1 text-xs text-gray-400"
                >
                  Hide from mapper when posted
                </label>
              </div>
            )}
            <div className="relative mb-8">
              <Controller
                name="priority"
                control={control}
                rules={{ required: 'Priority is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    inputId="priority-input"
                    className="formInput select"
                    isDisabled={areButtonsDisabled}
                    styles={formSelectStyle(errors, 'priority')}
                    options={priorityFormSelect}
                  />
                )}
              />
              <label htmlFor="priority-input" className="formLabel required">
                Priority
              </label>
              {errors.priority?.type === 'required' && (
                <p className="formError">{errors.priority.message}</p>
              )}
            </div>
            <div className="relative mb-8">
              <input
                id="project_tracking"
                type="text"
                className="formInput"
                {...register('project_tracking')}
              />
              <label htmlFor="project_tracking" className="formLabel">
                Project Tracking
              </label>
            </div>
            <div className="relative mb-8">
              <NumberInputWithoutScroll
                id="rate"
                label="Rate ($)"
                registerProps={register('rate', {
                  required: 'Rate is required',
                })}
                className={`formInput required${
                  errors.rate ? ' validationError' : ''
                }`}
                labelClassName="formLabel required"
                step="0.01"
              />
              {errors.rate?.type === 'required' && (
                <p className="formError">{errors.rate.message}</p>
              )}
            </div>
            <div className="relative mb-8">
              <Controller
                name="rate_type"
                defaultValue={RATE_TYPES_SELECT[0]}
                control={control}
                rules={{ required: 'Rate Type is required' }}
                render={({ field }) => (
                  <Select
                    {...field}
                    inputId="rate-type-input"
                    className="formInput select"
                    isDisabled={areButtonsDisabled}
                    styles={formSelectStyle(errors, 'rate-type')}
                    options={RATE_TYPES_SELECT}
                  />
                )}
              />
              <label htmlFor="rate-type-input" className="formLabel required">
                Rate Type
              </label>
              {errors.rate_type?.type === 'required' && (
                <p className="formError">{errors.rate_type.message}</p>
              )}
            </div>
            <div className="relative mb-8">
              <Controller
                name="available_at"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    id="available_at"
                    className="formInput"
                    wrapperClassName="formDatepicker"
                    dateFormat="yyyy-MM-dd"
                    onChange={(date) => field.onChange(date)}
                    selected={assignedUser ? new Date() : field.value}
                    readOnly={assignedUser}
                    autoComplete="off"
                  />
                )}
              />
              <label htmlFor="available_at" className="formLabel">
                Date Available
              </label>
            </div>
          </>
        )}
        <div className="relative">
          <textarea
            id="admin_description"
            type="text"
            rows="3"
            className="textarea form "
            {...register('admin_description', {
              maxLength: MAX_TEXT_LENGTH,
            })}
          />
          <label htmlFor="admin_description" className="formAreaLabel">
            Description
          </label>
          {errors.admin_description?.type === 'maxLength' && (
            <p className="absolute text-red-500 w-80 mt-1 text-xs">
              Description can have up to {MAX_TEXT_LENGTH} characters
            </p>
          )}
        </div>
        {!quickJobCreation && (
          <>
            <div className="relative">
              <textarea
                id="admin_notes"
                type="text"
                rows="3"
                className="textarea form "
                {...register('admin_notes', {
                  maxLength: MAX_TEXT_LENGTH,
                })}
              />
              <label htmlFor="admin_notes" className="formAreaLabel">
                Admin Notes
              </label>
              {errors.admin_notes?.type === 'maxLength' && (
                <p className="absolute text-red-500 w-80 mt-1 text-xs">
                  Admin notes can have up to {MAX_TEXT_LENGTH} characters
                </p>
              )}
            </div>
            <div className="relative">
              <div className="formSectionTitle">Protocol:</div>
              <div className="formSection">
                <div className="relative w-[30%]">
                  <label htmlFor="protocolDhpp" className="formLabel">
                    DHPP
                  </label>
                  <input
                    id="protocolDhpp"
                    type="text"
                    className="formInput inline"
                    {...register('vaccine_protocol.dhpp')}
                  />
                </div>
                <div className="relative w-[30%]">
                  <label htmlFor="protocolFvrcp" className="formLabel">
                    FVRCP
                  </label>
                  <input
                    id="protocolFvrcp"
                    type="text"
                    className="formInput inline"
                    {...register('vaccine_protocol.fvrcp')}
                  />
                </div>
                <div className="relative w-[30%]">
                  <label htmlFor="protocolRabies" className="formLabel">
                    Rabies
                  </label>
                  <input
                    id="protocolRabies"
                    type="text"
                    className="formInput inline"
                    {...register('vaccine_protocol.rabies')}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        <div className="relative formServerError mt-4">{serverError}</div>
        <div className="formButtonsPanel">
          <OutlineButton
            outlineColor="border-gray"
            textColor="text-gray"
            text="Cancel"
            widthClass="w-[120px]"
            heightClass="h-10"
            onClick={onCancel}
            disabled={areButtonsDisabled}
          />
          <ColoredButton
            fillColor="bg-vs-green"
            text="Save"
            widthClass="w-[120px]"
            heightClass="h-10"
            submit={true}
            disabled={areButtonsDisabled}
          />
        </div>
      </form>
    </>
  );
}
