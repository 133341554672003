import { useState } from 'react';
import { usePopper } from 'react-popper';

export const JobStatusCellIcon = ({
  testId,
  offset,
  iconSrc,
  displayMessage,
  actionHandler = null,
  additionalClasses = null,
}) => {
  let [referenceElement, setReferenceElement] = useState();
  let [popperElement, setPopperElement] = useState();
  let [displayTooltip, setDisplayTooltip] = useState(false);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: offset,
        },
      },
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top'],
        },
      },
    ],
  });

  return (
    <div className="group inline whitespace-normal">
      <img
        className={`pl-2 inline ${additionalClasses}`}
        src={iconSrc}
        data-testid={testId}
        alt="icon"
        ref={setReferenceElement}
        onMouseEnter={() => setDisplayTooltip(true)}
        onMouseLeave={() => setDisplayTooltip(false)}
        {...(actionHandler && {
          onClick: () => actionHandler(),
        })}
      />
      {displayTooltip && (
        <div
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          className="popper-status-tooltip"
        >
          <div id="arrow" data-popper-arrow></div>
          <p className="whitespace-normal">{displayMessage}</p>
        </div>
      )}
    </div>
  );
};
