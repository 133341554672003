import { createSlice } from '@reduxjs/toolkit';
import {
  userLogin,
  loadUserDetails,
  logout,
  resetState,
  refreshTokens,
  storeTokensInStorage,
} from './userActions';
import jwt from 'jwt-decode';

let token = localStorage.getItem('token');
let refreshToken = localStorage.getItem('refreshToken');
const userToken = token ? token : null;
const userRefreshToken = refreshToken ? refreshToken : null;

const initialState = {
  userInfo: null,
  userId: userToken ? jwt(userToken).user_id : null,
  tokenExpiry: userToken ? jwt(userToken).exp : null,
  refreshToken: refreshToken,
  refreshTokenExpiry: userRefreshToken ? jwt(userRefreshToken).exp : null,
  refreshTokenInProgress: false,
  loginError: null,
  loginSuccess: false,
  loading: false,
  token: userToken,
  userTableConfigurations: null,
  activeUserTableConfiguration: null,
};
export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    //called when token refresh was triggered in axios interceptor
    //for all others we are dispatching refreshTokens
    updateTokens: (state, { payload }) => {
      let decoded = jwt(payload.token);
      let decodedRefresh = jwt(payload.refresh_token);
      state.loading = false;
      state.token = payload.token;
      state.refreshToken = payload.refresh_token;
      state.userId = decoded.user_id;
      state.tokenExpiry = decoded.exp;
      state.refreshTokenExpiry = decodedRefresh.exp;
      state.refreshTokenInProgress = false;
      storeTokensInStorage(payload);
    },
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.token = payload.token;
      state.userId = payload.userId;
      state.tokenExpiry = payload.tokenExpiry;
      state.refreshToken = payload.refreshToken;
      state.refreshTokenExpiry = payload.refreshTokenExpiry;
      state.refreshTokenInProgress = false;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.token = null;
      state.error = payload;
      state.tokenExpiry = null;
      state.refreshTokenExpiry = null;
      state.refreshToken = null;
      state.refreshTokenInProgress = false;
    },
    [refreshTokens.pending]: (state) => {
      state.refreshTokenInProgress = true;
    },
    [refreshTokens.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.token = payload.token;
      state.userId = payload.userId;
      state.tokenExpiry = payload.tokenExpiry;
      state.refreshToken = payload.refreshToken;
      state.refreshTokenExpiry = payload.refreshTokenExpiry;
      state.refreshTokenInProgress = false;
    },
    [refreshTokens.rejected]: (state) => {
      state.loading = false;
      state.refreshTokenInProgress = false;
      state.token = null;
      state.error = null;
      state.tokenExpiry = null;
      state.refreshTokenExpiry = null;
      state.refreshToken = null;
    },
    [loadUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [loadUserDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = {
        id: payload.id,
        firstName: payload.first_name,
        lastName: payload.last_name,
        role: payload.role,
        email: payload.email,
        displayName: payload.display_name,
      };
    },
    [loadUserDetails.rejected]: (state) => {
      state.loading = false;
    },
    [logout]: (state) => {
      localStorage.removeItem('token'); // deletes token from storage
      localStorage.removeItem('refreshToken'); // deletes token from storage
      state.loading = false;
      state.refreshTokenInProgress = false;
      state.userInfo = null;
      state.userTableConfigurations = null;
      state.activeUserTableConfiguration = null;
      state.token = null;
      state.tokenExpiry = null;
      state.refreshTokenExpiry = null;
      state.refreshToken = null;
      state.error = null;
    },
    [resetState]: () => initialState,
  },
});
export const { updateTokens } = userSlice.actions;
export default userSlice.reducer;
