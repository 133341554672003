import { createPortal } from 'react-dom';
import { applyFilters } from '../../helpers/tableFiltersHelper';
import ColoredButton from '../common/ColoredButton';
import OutlineButton from '../common/OutlineButton';
import arrow from '../../assets/icons/arorw_right.svg';

export const renderOverlayMessage = ({
  rowCount,
  filterActive,
  isShowAllFilters,
}) => {
  let overlayMessage;
  if (rowCount === 0) {
    overlayMessage = 'No data found for applied filters';
  } else if (!filterActive) {
    overlayMessage = 'No filters applied';
  } else if (!isShowAllFilters && (!rowCount || rowCount < 0))
    overlayMessage = 'At least one mandatory filter has to be selected';

  return createPortal(
    <div className="absolute w-96 text-center top-[30%] left-[50%] -ml-48 text-base text-gray-400">
      {overlayMessage}
    </div>,
    document.querySelector('.ag-center-cols-viewport')
  );
};

export const renderFilterActions = (
  gridApi,
  isRequest,
  isDataRendered,
  resetFilters,
  setShowLeavePageConfirmationDialog
) => {
  return (
    <div className="flex justify-center py-2">
      <OutlineButton
        onClick={() =>
          resetFilters(gridApi).then(() =>
            setShowLeavePageConfirmationDialog(false)
          )
        }
        outlineColor="border-purple-vs"
        bgClass="bg-white"
        text="Clear Filters"
        widthClass="w-[100px]"
        disabled={isRequest || isDataRendered}
      />
      <ColoredButton
        onClick={() => applyFilters(gridApi)}
        text="Apply Filters"
        disabled={isRequest || isDataRendered}
        fillColorClass="bg-purple-vs"
        hoverColorClass="hover:bg-purple-vs-hover"
        widthClass="w-[100px]"
        border="border border-solid border-purple-vs outline-none"
      />
    </div>
  );
};

export const renderJobPageFilterActions = (
  gridApi,
  isRequest,
  isDataRendered,
  resetFilters,
  resetQuickFilter
) => {
  return (
    <div className="flex justify-center py-2">
      <OutlineButton
        onClick={() => resetFilters(gridApi).then(() => resetQuickFilter())}
        outlineColor="border-purple-vs"
        bgClass="bg-white"
        text="Clear Filters"
        widthClass="w-[100px]"
        disabled={isRequest || isDataRendered}
      />
      <ColoredButton
        onClick={() => applyFilters(gridApi)}
        text="Apply Filters"
        disabled={isRequest || isDataRendered}
        fillColorClass="bg-purple-vs"
        hoverColorClass="hover:bg-purple-vs-hover"
        widthClass="w-[100px]"
        border="border border-solid border-purple-vs outline-none"
      />
    </div>
  );
};

export const ArrowDown = () => {
  return (
    <img
      src={arrow}
      width="12"
      height="12"
      alt=""
      style={{ transform: 'rotate(90deg)', marginLeft: '10px' }}
    />
  );
};
