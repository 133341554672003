import {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import useWebSocket from 'react-use-websocket';
import {
  jobDetailsEditSaved,
  loadJobDetails,
} from '../../features/job/jobActions';
import ConfirmationModal from '../common/ConfirmationModal';
import {
  confirmButtonClicked,
  markJobCompletedClicked,
  markJobCompleteMappingClicked,
  modalClosed,
} from '../../features/modal/modalSlice';
import {
  COMPLETE_JOB_MODAL,
  JOB_DETAILS_MODAL,
  COMPLETE_MAPPING_JOB_MODAL,
  INVOICE_JOB_MODAL,
  UNHIDE_JOB_MODAL,
  COMPLETE_MAPPING_JOB_PRACTICE_TYPE_WARNING_MODAL,
  COMPLETE_MAPPING_JOB_NOT_ALLOWED_MODAL,
  COMPLETE_JOB_NOT_ALLOWED_MODAL,
  COMPLETE_JOB_PRACTICE_TYPE_WARNING_MODAL,
} from '../../constants/modals';
import JobTableMappings from './JobTableMappings';
import {
  completeJob,
  completeMapping,
  invoiceJobs,
  unhideJob,
} from '../../services/jobServices';
import JobDetailsModal from '../job/details/JobDetailsModal';
import {
  COMPLETE_JOB_ACTION_LABEL,
  COMPLETE_JOB_PRACTICE_TYPE_MISSING_LABEL,
  COMPLETE_JOB_PRACTICE_TYPE_MISSING_TEXT,
  getCompleteJobDialogText,
  getTimeSpentConfirmationDialogText,
  INVOICE_JOB_ACTION_LABEL,
  INVOICE_JOB_DIALOG_TEXT,
  RATE_TYPE_PER_HOUR,
  COMPLETE_MAPPING_ACTION_LABEL,
  COMPLETE_MAPPING_DIALOG_TEXT,
  COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_LABEL,
  COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_TEXT,
  COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_WARRNING_TEXT,
  COMPLETE_MAPPING_WARNING_DIALOG_IMPORTANT_TEXT,
  REFRESH_JOB_LABEL,
  REFRESH_JOB_WARNING_DIALOG_TEXT,
  UNHIDE_JOB_ACTION_LABEL,
  UNHIDE_JOB_DIALOG_TEXT,
} from '../../constants/jobConstants';
import { jobMappingsAllowedForUser } from '../../helpers/jobsHelper';
import { formatHours } from '../../helpers/jobsTableHelper';
import OutlineButton from '../common/OutlineButton';
import { openViewJobDetails } from '../../features/modal/modalActions';
import ColoredButton from '../common/ColoredButton';
import { ErrorContext } from '../../ErrorContext';
import { createPortal } from 'react-dom';
import { logger } from '../../services/logger';
import { refreshPage } from '../../helpers/commonHelper';
import ToasterNotification from '../common/ToasterNotification';
import RefreshNotification from '../common/RefreshNotification';

export default function JobMapping() {
  const { setErrorAlert } = useContext(ErrorContext);
  let { jobId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { mappingJobDetails, loadingJobDetails, loadingJobDetailsError } =
    useSelector((state) => state.job);
  const modal = useSelector((state) => state.modal);
  const { userInfo, token } = useSelector((state) => state.user);
  const detailsLoaded =
    !loadingJobDetails && mappingJobDetails && !loadingJobDetailsError;
  const [jobMappingAllowed, setJobMappingAllowed] = useState(false);
  const [showRefreshNotification, setShowRefreshNotification] = useState(false);
  const [showToasterNotification, setShowToasterNotification] = useState(false);
  const [lastRefreshedAt, setLastRefreshedAt] = useState(null);

  const onJobRefresh = useCallback(
    (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'disconnect' && data.reason === 'unauthorized') {
        logger.logEvent(
          `Unauthorized WS Connection Attempt: ${JSON.stringify(data)}`
        );
      } else if (
        data.message?.last_refreshed_at &&
        data.message?.last_refreshed_at !== lastRefreshedAt
      ) {
        logger.logEvent(`Last refreshed at message received for job ${jobId}`);
        setLastRefreshedAt(data.message.last_refreshed_at);
        setShowRefreshNotification(true);
        setShowToasterNotification(true);
      }
    },
    [lastRefreshedAt]
  );

  const subscribeMsg = useMemo(() => {
    return {
      command: 'subscribe',
      identifier: JSON.stringify({
        channel: 'JobUpdatesChannel',
        mapping_job_id: jobId,
      }),
    };
  }, [token]);

  const { sendJsonMessage } = useWebSocket(
    import.meta.env.VITE_SERVER_API_WS_URL,
    {
      onOpen: () => sendJsonMessage(subscribeMsg),
      onMessage: onJobRefresh,
      onReconnectStop: () =>
        logger.catchError('Max number of connection attempts exceeded.'),
      onError: (event) => {
        if (event instanceof Error) {
          logger.catchError(event);
        } else {
          const readyState = event.currentTarget?.readyState ?? 'unknown'; // ?? works if readyState is 0, where || would consider 0 as a falsy value
          logger.logEvent(`WS error with ready state: ${readyState}`);
        }
      },
      shouldReconnect: () => true,
      onClose: (event) => {
        logger.logEvent(`WS connection closed with code: ${event.code}`);
      },
      protocols: ['actioncable-v1-json', token],
    }
  );

  const completeMappingJobHandler = () => {
    dispatch(confirmButtonClicked());
    completeMapping(jobId)
      .then(({ data }) => {
        dispatch(jobDetailsEditSaved(data));
        navigate('/jobs');
      })
      .catch((err) => {
        setErrorAlert({ error: err });
      })
      .finally(() => {
        dispatch(modalClosed());
      });
  };

  const invoiceJobHandler = () => {
    dispatch(confirmButtonClicked());
    invoiceJobs([jobId])
      .then(() => {
        navigate('/jobs');
      })
      .catch((err) => {
        setErrorAlert({ error: err });
      })
      .finally(() => {
        dispatch(modalClosed());
      });
  };

  const completeJobHandler = () => {
    dispatch(confirmButtonClicked());
    completeJob(jobId)
      .then(() => {
        navigate('/jobs');
      })
      .catch((err) => {
        setErrorAlert({ error: err });
      })
      .finally(() => {
        dispatch(modalClosed());
      });
  };

  const unhideJobHandler = () => {
    dispatch(confirmButtonClicked());
    unhideJob(jobId)
      .then(() => {
        navigate('/jobs');
      })
      .catch((err) => {
        setErrorAlert({ error: err });
      })
      .finally(() => {
        dispatch(modalClosed());
      });
  };

  useEffect(() => {
    dispatch(loadJobDetails(jobId));
  }, []);

  useEffect(() => {
    if (mappingJobDetails) {
      setJobMappingAllowed(
        jobMappingsAllowedForUser(mappingJobDetails, userInfo)
      );
    }
  }, [mappingJobDetails]);

  const createNotificationPortal = () => {
    if (document.querySelector('#notification-placeholder')) {
      return createPortal(
        <>
          {showRefreshNotification && (
            <RefreshNotification
              message="There may be additional mappings in this job."
              testId="notification-status-bar"
            />
          )}
        </>,
        document.querySelector('#notification-placeholder')
      );
    }
  };

  const createNotification = useMemo(
    () => createNotificationPortal(),
    [showRefreshNotification]
  );

  return (
    <div id="mappings" className="flex flex-col h-full flex-grow-1">
      {detailsLoaded && jobMappingAllowed && (
        <Fragment>
          <JobTableMappings jobDetails={mappingJobDetails} />
          {createNotification}
          {showToasterNotification && (
            <ToasterNotification
              actionCallback={refreshPage}
              dismissCallback={() => setShowToasterNotification(false)}
            />
          )}
          <ConfirmationModal
            title={REFRESH_JOB_LABEL}
            text={REFRESH_JOB_WARNING_DIALOG_TEXT}
            operationType="warning"
            customButtons={() => {
              return (
                <>
                  <OutlineButton
                    text="Close"
                    heightClass="h-10"
                    widthClass="w-[150px]"
                    onClick={() => dispatch(modalClosed())}
                  />
                  <ColoredButton
                    text="Refresh Page"
                    heightClass="h-10"
                    widthClass="w-[150px]"
                    fillColorClass="bg-orange"
                    border="border border-solid border-orange outline-none"
                    hoverColorClass="hover:bg-orange-hover"
                    onClick={refreshPage}
                  />
                </>
              );
            }}
            onClose={() => dispatch(modalClosed())}
            isOpen={
              [
                COMPLETE_MAPPING_JOB_MODAL,
                COMPLETE_MAPPING_JOB_NOT_ALLOWED_MODAL,
                COMPLETE_MAPPING_JOB_PRACTICE_TYPE_WARNING_MODAL,
                COMPLETE_JOB_NOT_ALLOWED_MODAL,
                COMPLETE_JOB_PRACTICE_TYPE_WARNING_MODAL,
                COMPLETE_JOB_MODAL,
              ].includes(modal.visibleModal) && showRefreshNotification
            }
          />
          <ConfirmationModal
            title={COMPLETE_MAPPING_ACTION_LABEL}
            importantText={
              modal.visibleModalImportantText ||
              COMPLETE_MAPPING_WARNING_DIALOG_IMPORTANT_TEXT
            }
            text={
              modal.visibleModalText ||
              (COMPLETE_MAPPING_DIALOG_TEXT + mappingJobDetails.rate_type ===
              RATE_TYPE_PER_HOUR
                ? getTimeSpentConfirmationDialogText(
                    formatHours(mappingJobDetails.mapped_time)
                  )
                : '')
            }
            buttonText="Yes"
            operationType={modal.visibleModalOperationType || 'warning'}
            onButtonClick={completeMappingJobHandler}
            onClose={() => dispatch(modalClosed())}
            isOpen={
              modal.visibleModal === COMPLETE_MAPPING_JOB_MODAL &&
              !showRefreshNotification
            }
            buttonsDisabled={modal.buttonsDisabled}
          />
          <ConfirmationModal
            title={COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_LABEL}
            text={COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_TEXT}
            operationType="warning"
            customButtons={() => {
              return (
                <OutlineButton
                  text="Open Job Details"
                  heightClass="h-10"
                  widthClass="w-[150px]"
                  onClick={async () => {
                    try {
                      await dispatch(
                        openViewJobDetails(mappingJobDetails.id)
                      ).unwrap();
                    } catch (error) {
                      setErrorAlert({ parsedError: error });
                    }
                  }}
                />
              );
            }}
            onClose={() => dispatch(modalClosed())}
            isOpen={
              modal.visibleModal === COMPLETE_MAPPING_JOB_NOT_ALLOWED_MODAL &&
              !showRefreshNotification
            }
          />
          <ConfirmationModal
            title={COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_LABEL}
            text={COMPLETE_MAPPING_PRACTICE_TYPE_MISSING_WARRNING_TEXT}
            customButtons={() => {
              return (
                <>
                  <OutlineButton
                    text="Open Job Details"
                    heightClass="h-10"
                    widthClass="w-[150px]"
                    onClick={async () => {
                      try {
                        await dispatch(
                          openViewJobDetails(mappingJobDetails.id)
                        ).unwrap();
                      } catch (error) {
                        setErrorAlert({ parsedError: error });
                      }
                    }}
                  />
                  <ColoredButton
                    text="Complete Anyway"
                    heightClass="h-10"
                    widthClass="w-[150px]"
                    fillColorClass="bg-orange"
                    border="border border-solid border-orange outline-none"
                    hoverColorClass="hover:bg-orange-hover"
                    onClick={() => {
                      dispatch(
                        markJobCompleteMappingClicked({
                          visibleModal: COMPLETE_MAPPING_JOB_MODAL,
                          unverifiedCodesExists: modal.unverifiedCodesExists,
                          allCodesVerified: modal.allCodesVerified,
                          ...(mappingJobDetails.rate_type ===
                            RATE_TYPE_PER_HOUR && {
                            mappedTime: formatHours(
                              mappingJobDetails.mapped_time
                            ),
                          }),
                        })
                      );
                    }}
                  />
                </>
              );
            }}
            onClose={() => dispatch(modalClosed())}
            isOpen={
              modal.visibleModal ===
                COMPLETE_MAPPING_JOB_PRACTICE_TYPE_WARNING_MODAL &&
              !showRefreshNotification
            }
          />
          <ConfirmationModal
            title={COMPLETE_JOB_PRACTICE_TYPE_MISSING_LABEL}
            text={COMPLETE_JOB_PRACTICE_TYPE_MISSING_TEXT}
            operationType="warning"
            customButtons={() => {
              return (
                <OutlineButton
                  text="Open Job Details"
                  heightClass="h-10"
                  widthClass="w-[150px]"
                  onClick={async () => {
                    try {
                      await dispatch(
                        openViewJobDetails(mappingJobDetails.id)
                      ).unwrap();
                    } catch (error) {
                      setErrorAlert({ parsedError: error });
                    }
                  }}
                />
              );
            }}
            onClose={() => dispatch(modalClosed())}
            isOpen={modal.visibleModal === COMPLETE_JOB_NOT_ALLOWED_MODAL}
          />
          <ConfirmationModal
            title={COMPLETE_JOB_PRACTICE_TYPE_MISSING_LABEL}
            text={COMPLETE_JOB_PRACTICE_TYPE_MISSING_TEXT}
            customButtons={() => {
              return (
                <>
                  <OutlineButton
                    text="Open Job Details"
                    heightClass="h-10"
                    widthClass="w-[150px]"
                    onClick={async () => {
                      try {
                        await dispatch(
                          openViewJobDetails(mappingJobDetails.id)
                        ).unwrap();
                      } catch (error) {
                        setErrorAlert({ parsedError: error });
                      }
                    }}
                  />
                  <ColoredButton
                    text="Complete Anyway"
                    heightClass="h-10"
                    widthClass="w-[150px]"
                    fillColorClass="bg-orange"
                    border="border border-solid border-orange outline-none"
                    hoverColorClass="hover:bg-orange-hover"
                    onClick={() => dispatch(markJobCompletedClicked())}
                  />
                </>
              );
            }}
            onClose={() => dispatch(modalClosed())}
            isOpen={
              modal.visibleModal === COMPLETE_JOB_PRACTICE_TYPE_WARNING_MODAL &&
              !showRefreshNotification
            }
          />
          <ConfirmationModal
            title={COMPLETE_JOB_ACTION_LABEL}
            text={getCompleteJobDialogText()}
            buttonText="Yes"
            onButtonClick={completeJobHandler}
            onClose={() => dispatch(modalClosed())}
            isOpen={
              modal.visibleModal === COMPLETE_JOB_MODAL &&
              !showRefreshNotification
            }
            buttonsDisabled={modal.buttonsDisabled}
          />
          <ConfirmationModal
            title={INVOICE_JOB_ACTION_LABEL}
            text={INVOICE_JOB_DIALOG_TEXT}
            buttonText="Yes, invoice"
            onButtonClick={invoiceJobHandler}
            onClose={() => dispatch(modalClosed())}
            isOpen={modal.visibleModal === INVOICE_JOB_MODAL}
            buttonsDisabled={modal.buttonsDisabled}
          />
          <ConfirmationModal
            title={UNHIDE_JOB_ACTION_LABEL}
            text={UNHIDE_JOB_DIALOG_TEXT}
            buttonText="Yes, unhide"
            onButtonClick={unhideJobHandler}
            onClose={() => dispatch(modalClosed())}
            isOpen={modal.visibleModal === UNHIDE_JOB_MODAL}
            buttonsDisabled={modal.buttonsDisabled}
          />
          <JobDetailsModal
            onClose={() => dispatch(modalClosed())}
            isOpen={modal.visibleModal === JOB_DETAILS_MODAL}
            title="Details"
          />
        </Fragment>
      )}
      {loadingJobDetailsError && (
        <div className="grid h-full place-items-center">
          {loadingJobDetailsError.description}
        </div>
      )}
      {mappingJobDetails && !jobMappingAllowed && !loadingJobDetailsError && (
        <div className="grid h-full place-items-center">
          You are not authorized for viewing this job
        </div>
      )}
    </div>
  );
}
