import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JobsTable from './JobsTable';
import Loader from '../common/Loader';
import { loadPractices } from '../../features/table/tableActions';
import JobDetailsModal from './details/JobDetailsModal';
import { modalClosed } from '../../features/modal/modalSlice';
import { JOB_DETAILS_MODAL } from '../../constants/modals';
import { ErrorContext } from '../../ErrorContext';
import { refreshPage } from '../../helpers/commonHelper';

export default function JobsHistory() {
  const { setErrorAlert } = useContext(ErrorContext);
  const [isLoaderVisible, setLoaderVisible] = useState(true);
  const { practices, preLoadingError } = useSelector(
    (state) => state.tableData
  );
  const modal = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  useEffect(() => {
    if (preLoadingError) {
      setErrorAlert({
        error: preLoadingError,
        buttonText: 'Try reloading',
        onButtonClick: refreshPage,
        closingButton: true,
      });
    }
  }, [practices]);

  useEffect(() => {
    if (!practices) {
      dispatch(loadPractices());
    } else if (practices) {
      setLoaderVisible(false);
    }
  }, [practices]);

  return (
    <>
      {isLoaderVisible && !preLoadingError && <Loader />}
      {!isLoaderVisible && !preLoadingError && (
        <div id="jobsHistory" className="flex flex-col h-full flex-grow-1">
          <JobsTable />
        </div>
      )}
      <JobDetailsModal
        onClose={() => dispatch(modalClosed())}
        isOpen={modal.visibleModal === JOB_DETAILS_MODAL}
        title="Details"
      />
    </>
  );
}
